import Swiper, { Navigation, Pagination } from 'swiper';
// import Swiper styles
Swiper.use([Navigation, Pagination]);
let map;

window.onload = function() {
    function initMap() {
        map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8,
        });
    }
    var swiper = new Swiper(".mySwiper", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        loop: true,
        // pagination: {
        //   el: ".swiper-pagination",
        // },
    });
};